import {React, useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import { homePageText } from "../text/Text";
import colorManager from "../Styles/ColorManager";
import { updateSelectedPage, updateUserType } from "../state/navbarSlice";
import startupsIMG from "../assets/sunset-in-cairo-right.png";
import investorsIMG from "../assets/sunset-in-cairo-left.png";
import navigate from "../HelperFunctions";
import PageTopMargin from "../Components/PageTopMargin";

const Hero = () => {
    return(
        <div style={styles.hero}>
            <h1>{homePageText.hero.title}</h1>
            <p>{homePageText.hero.body}</p>
        </div>
            
    )
}

const Images = () => {
    const dispatch = useDispatch();
    const [hovered, setHovered] = useState(null); // Track the hovered image index
  
    const handleHover = (index) => {
      setHovered(index);
    };
  
    return (
      <div style={styles.ImagesContainer}>
        <div
          style={{
            ...styles.Image,
            backgroundImage: `url(${investorsIMG})`,
            filter: hovered !== 0 ? "grayscale(100%)" : "",
          }}
          onMouseEnter={() => handleHover(0)} // Set the hovered index on mouse enter
          onMouseLeave={() => handleHover(null)} // Reset the hovered index on mouse leave
          onClick={() =>{
            handleHover(0)
            navigate('/investors')
          }}
        >
          <div style={styles.cardOverlay}>
            <h2>Investors</h2>
            <h3> Explore if we match your investment appetite and criteria </h3>
          </div>
        </div>
        <div
          style={{
            ...styles.Image,
            backgroundImage: `url(${startupsIMG})`,
            filter: hovered !== 1 ? "grayscale(100%)" : "",
          }}
          onMouseEnter={() => handleHover(1)} // Set the hovered index on mouse enter
          onMouseLeave={() => handleHover(null)} // Reset the hovered index on mouse leave
          onClick={() => {
            handleHover(1) //for colour
            navigate('/startups')
          }}
        >
          <div style={styles.cardOverlay}>
            <h2>Start-Ups</h2>
            <h3>Explore if we are the right match for your journey</h3>
          </div>
        </div>
      </div>
    );
  };

export default function HomePage(){
    const dispatch = useDispatch();
    useEffect(()=> {dispatch(updateSelectedPage('about-us'))}, [])


    return(
        <div style={styles.HomePage}>
            <PageTopMargin/>
            <Hero/>
            <Images/>
        </div>
        
    )
}

const styles = {
    hero: {
      backgroundColor: colorManager.darkBlue,
      color: colorManager.white,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "1rem 2rem",
    },
    ImagesContainer: {
      flex: 1, // Expand and fill the remaining vertical space
      height: "30rem",
      display: "flex",
      flexDirection: "row",
      backgroundColor: colorManager.darkBlue,
    },
    Image: {
      flexGrow: 1, // Expand and fill the remaining horizontal space
      cursor: "pointer",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      margin: "0rem 1rem",
      borderRadius: "1rem",
      position: "relative", // Add position relative for overlay positioning
    },
    cardOverlay: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      padding: "1rem",
      borderBottomLeftRadius: "1rem",
      borderBottomRightRadius: "1rem",
      color:colorManager.white
    },
    subText:{
        fontWeight: 'normal',
        fontSize:'1.2rem'
    }
  };