import React from "react";
import { AiFillLinkedin, AiFillPhone, AiFillMail } from "react-icons/ai";
import { IconContext } from "react-icons";

export default function ContactInfo({ linkedInName, linkedInLink, phoneNumber, email }) {
  const handleLinkedInClick = () => {
    // Open LinkedIn link in a new window
    window.open(linkedInLink);
  };

  return (
    <div style={styles.wrapper}>
      <IconContext.Provider value={{ color: "white", size: "2rem" }}>
        <div style={styles.container}>
          <div style={{...styles.item, cursor: "pointer"}} onClick={handleLinkedInClick}>
            <div style={styles.icon}>
              <AiFillLinkedin />
            </div>
            <p style={styles.text}>{linkedInName}</p>
          </div>
          <div style={styles.item}>
            <div style={styles.icon}>
              <AiFillPhone />
            </div>
            <p style={styles.text}>{phoneNumber}</p>
          </div>
          <div style={styles.item}>
            <div style={styles.icon}>
              <AiFillMail />
            </div>
            <p style={styles.smallText}>{email}</p>
          </div>
        </div>
      </IconContext.Provider>
    </div>
  );
}

const styles = {
  wrapper: {},
  container: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    justifyContent: "center",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "white",
    textAlign: "center",
  },
  icon: {
    paddingRight: "1.5rem",
  },
  text: {
    fontSize: "1.3rem",
  },
  smallText: {
    fontSize: "1rem",
  },
};
