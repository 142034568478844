import React from "react";
import ContactInfo from "./ContactInfo";
import colorManager from "../Styles/ColorManager";

export default function InfoComponent({  name, title, description, linkedInName, linkedInLink, phoneNumber, email, position, image}) {
    return (
        <div>
            <div style={styles.container}>
                {
                    position === "right" &&
                    <div style={styles.textInfo}>
                        <h5 style={styles.mainText}>{name}</h5>
                        <p style={styles.jobTitle}>{title}</p>
                        <p style={styles.plainText}>{description}</p>
                    </div>
                }
                <div style={styles.imageInfo}>
                    <div style={position === "right" ? styles.RightImageInfoWrapper : styles.imageInfoWrapper}>
                        <div style={styles.contactInfoContainer}>
                            <ContactInfo linkedInName={linkedInName} linkedInLink={linkedInLink} phoneNumber={phoneNumber} email={email} />
                        </div>
                        <div style={{...styles.fullImage, backgroundImage:`url(${image})`}}></div>
                    </div>
                </div>
                {
                    position !== "right" &&
                    <div style={styles.textInfo}>
                        <h5 style={styles.mainText}>{name}</h5>
                        <p style={styles.jobTitle}>{title}</p>
                        <p style={styles.plainText}>{description}</p>
                    </div>
                }
            </div>
        </div>
    )
}


const styles = {
    container: {
        display: "flex",
        //flex: 1, // Expand and fill the remaining vertical space
        flexGrow: 1,
        height: "65vh",
        flexDirection: "row",
        justifyContent: "space-between",
        //alignItems: "center",
    },
    imageInfo: {
        width: "50%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    textInfo: {
        display: "flex",
        width: "50%",
        //flexGrow: 1,
        marginLeft: "1.5rem",
        flexDirection: "column",
        justifyContent: "center",
    },
    mainText: {
        margin: "1rem",
        marginLeft: "0",
        fontSize: "2rem",
    },
    jobTitle: {
        marginBottom: "1rem",
        fontSize: "1.8rem",
    },
    plainText: {
        marginBottom: "1rem",
        fontSize: "1rem",
    },
    imageInfoWrapper: {
        backgroundColor: colorManager.darkBlue,
        height: "60%",
        borderRadius: "0 1.25rem 1.25rem 0",
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "row",
    },
    RightImageInfoWrapper: {
        backgroundColor: colorManager.darkBlue,
        height: "60%",
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "row",
        borderRadius: "1.25rem 0 0 1.25rem ",
    },
    fullImage: {
        display: "flex",
        justifyContent: "flex-start",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top",
        borderRadius: "1.25rem",
        height: "22rem",
        width: "40%",
        position: "relative",
        bottom: "2rem",

    },
    contactInfoContainer: {
        width: "40%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }
}