
import React from 'react';
import colorManager from '../Styles/ColorManager';

const PageTopMargin = () => {
  return (
    <div style={{
        backgroundColour: colorManager.white,
        marginTop: '4.7rem'
    }}>
    </div>
  );
}

export default PageTopMargin;
