import React from "react";
import colorManager from "../Styles/ColorManager";

export default function PageHeader({ title}) {
    const styles = {
        overlay: {
            backgroundColor: colorManager.darkBlue,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding:"2rem 0rem",
        },
        title: {
            color: "white",
            fontWeight: "bold",
            fontSize: "2.5rem",
            margin:"0rem"
        },
    }
    return (
        <>
            <div style={styles.overlay}>
                <p style={styles.title}>{title}</p>
            </div>
        </>
    )
}