import React from "react";
import locationIMG from '../../assets/location.png'
import { corporate_info } from "../../data";
export default function ContactUsInfo() { 
    return (
        <div style={styles.container}>
            <div style={styles.wrapper}>
                <div style={styles.textContainer}>
                    <p> <b>Email: </b>{corporate_info.email}</p>
                    <p> <b>Headquarters: </b>{corporate_info.address}</p>
                </div>
                <div style={styles.iframeContainer}>
                    <iframe src={corporate_info.iframeLocationSrc} style={styles.googleIframe} frameborder="0" ></iframe>
                </div>
            </div>
        </div>
    )
}

const styles = {
    wrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    imgContainer: {
        width: "50%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    textContainer: {
        width: "50%",
        marginLeft: "2rem",
        fontSize: "1.4rem",
    },
    hyperText: {
        width: "80%",
    },
    iframeContainer: {
        marginRight:"2rem"
    },
    googleIframe :{
        width : "40rem",
        height : "20rem"
    }
}