import React, { useEffect } from "react";
import ContactUsIMG from '../assets/Contact-us.jpg';
import ContactUsInfo from "../Components/ContactUsComponents/ContactUsInfo";
import { useDispatch } from "react-redux";
import { updateSelectedPage } from "../state/navbarSlice";
import PageHeader from "../Components/PageHeader";
import PageTopMargin from "../Components/PageTopMargin";


export default function ContactUsPage() {
    const dispatch = useDispatch();
    useEffect(()=> {dispatch(updateSelectedPage('contact-us'))}, [])

    return (
        <>
            <PageTopMargin/>
            <PageHeader title={"Contact us"} />
            <div style={styles.container}>
                <ContactUsInfo/>
            </div>
        </>
    )
}

const styles = {
    container: {
      padding: "3rem 0rem",
    },
};