import React, { useState, useEffect } from "react";
import MeetingForm from "../Components/ModuleComponents/MeetingForm";
import { useDispatch} from "react-redux";
import { updateSelectedPage} from "../state/navbarSlice";
import PageHeader from "../Components/PageHeader";
import colorManager from "../Styles/ColorManager";
import { modules, startups } from "../data";
import ApplicationForm from "../Components/ModuleComponents/ApplicationForm";
import ComponentSwitcher from "../Components/ComponentSwitcher";
import PageTopMargin from "../Components/PageTopMargin";


const JourneyRider = () => {
  const { description, support, ecosystem } = modules.startups.journeyModule;
  return(
    <>
      <p>{description}</p>
      <p>{support}</p>
      <p>{ecosystem}</p>
    </>
  );
}

const AngelInvestors = () => {
  const { description, passiveInvestments, activeInvestments } = modules.startups.angelInvestorModule;
  return(
    <>
      <p>{description}</p>
      <p>{passiveInvestments}</p>
      <p>{activeInvestments}</p>
    </>
  );
}


export default function StartupsPage() {
  const dispatch = useDispatch();
  const [showMeetingForm, setShowMeetingForm] = useState(false);

  const handleRequestMeeting = () => {
    setShowMeetingForm(true);
  };

  useEffect(() => {
    dispatch(updateSelectedPage("about-us"));
  }, []);

  return (
    <>
      <PageTopMargin/>
      <PageHeader title={"Startups"} />
      <div style={styles.container}>
        <p style={styles.descriptionText}>{modules.startups.description}</p>
        
        <ul>
          {Object.entries(modules.startups.investmentCriteria).map(([key, value]) => (
            <li key={key} style={styles.listItem}>
              <strong>{key}</strong>: {value}
            </li>
          ))}
        </ul>
        
        <p style={styles.descriptionText}>{modules.startups.outro}</p>
        <div style={styles.componentSwitcher}>
          <ComponentSwitcher name1={"Journey Rider"} name2={"Angel Investors"} component1={<JourneyRider/>} component2={<AngelInvestors/>}/>
        </div>
        {!showMeetingForm && (
          <button onClick={handleRequestMeeting} style={styles.button}>Ask for investment</button>
        )}
        
        {showMeetingForm && <ApplicationForm/>}
      </div>
    </>
  );
}

const styles = {
  container: {
    padding: "2rem",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    flex: 1,
  },
  descriptionText: {
    fontSize: "1.5rem",
    margin:"0rem",
    fontWeight:"bold"
  },
  button: {
    padding: "1rem 2rem",
    background: colorManager.darkBlue,
    color: colorManager.white,
    width:"50vw",
    border: "none",
    borderRadius: "0.5rem",
    fontSize: "1.2rem",
    fontWeight: "bold",
    cursor: "pointer",
    outline: "none",
  },
  listItem:{
    margin:"1rem 0rem",
  },
  componentSwitcher:{
    backgroundColor: colorManager.cyan,
    padding: "1rem 2rem",
    margin: "2rem -2rem"
  }
};
