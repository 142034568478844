
import { React, useState, useEffect, useRef } from "react";
import colorManager from "../../Styles/ColorManager";
import emailjs from '@emailjs/browser'

export default function ApplicationForm() {
    const form = useRef();

    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [date, setDate] = useState('');

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
    const handleDateChange = (event) => {
        setDate(event.target.value);
    };

    const handleSubmit = (e) => {
        console.log(date, name, phoneNumber, email, message)
        let varDate = new Date(date); //dd-mm-YYYY
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        if (date === '' || varDate <= today || name === '' || phoneNumber === '' || email === '' || message === '') {
            alert("Invalid Credentials")
            return
        }
        else {
            alert("Good credentials")
        }
        e.preventDefault();

        // emailjs.sendForm('service_kzdisej', 'template_95a7osj', form.current, '46TAJ9n3YQbjELC6O')
        //     .then((result) => {
        //         console.log(result);
        //     }, (error) => {
        //         console.log(error);
        //     });
        // e.target.reset();
        // TODO: handle form submission
    }

    return (
        <>
            <div style={styles.wrapper}>
                <h2 style={styles.label}>Request a meeting</h2>
                <form onSubmit={handleSubmit} ref={form}>

                    <div style={styles.container}>
                        <div style={styles.secondRow}>
                            <div style={styles.inputContainer}>
                                <label style={styles.labelText} htmlFor="name">Name*</label>
                                <input style={styles.inputBox} type="text" id="name" value={name} onChange={handleNameChange} />
                            </div>
                            <div style={styles.inputContainer}>
                                <label style={styles.labelText} htmlFor="phoneNumber">Phone Number*</label>
                                <input style={styles.inputBox} type="tel" id="phoneNumber" value={phoneNumber} onChange={handlePhoneNumberChange} />
                            </div>
                        </div>
                        <div style={styles.thirdRow}>
                            <div style={styles.inputContainer}>
                                <label style={styles.labelText} htmlFor="user_email">Email*</label>
                                <input style={styles.inputBox} type="email" id="user_email" value={email} onChange={handleEmailChange} />
                            </div>
                        </div>
                        <label style={styles.labelText} htmlFor="message">Description*</label>
                        <textarea style={styles.descText} id="message" value={message} onChange={handleMessageChange} />
                        <div style={styles.inputContainer}>
                            <label style={styles.labelText} htmlFor="meeting_date">Pick a suitable Time*</label>
                            <input type="date" id="meeting_date" value={date} style={styles.datePicker} onChange={handleDateChange} />
                        </div>
                    </div>
                    <div style={styles.buttonContainer}>
                        <button type="submit" style={styles.submitButton}>SUBMIT</button>
                    </div>
                </form>
            </div>
        </>
    );
}

const styles = {
    wrapper: {
        display: "flex",
        backgroundColor: colorManager.cyan,
        height: "60vh",
        padding: "2rem",
        borderRadius: "0.8rem",
        flexDirection: "column",
        minWidth: 0,
        width: "calc(100% - 4rem)", // Add this line to allow the wrapper to shrink when needed
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "1rem",
        marginLeft: "1rem",
    },
    inputContainer: {
        width: "45%",
        display: "flex",
        marginBottom: "0.5rem",
        marginTop: "0.5rem",
        flexDirection: "column",
    },
    container: {
        marginLeft: "1rem",
    },
    labelText: {
        fontSize: "1rem",
        paddingBottom: "0.5rem",
    },
    inputBox: {
        width: "100%",
        borderRadius: "0.25rem",
        border: "none",
        height: "4vh",
    },
    secondRow: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    thirdRow: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    descText: {
        height: "7vh",
        width: "100%",
        alignSelf: "center",
        borderRadius: "0.25rem",
        border: "none",
        resize: "none",
    },
    datePicker: {
        textAlign: "center",
        width: "70%",
        borderRadius: "0.25rem",
        border: "none",
        height: "4vh",
    },
    submitButton: {
        width: "10%",
        height: "5vh",
        borderRadius: "0.25rem",
        border: "none",
        backgroundColor: colorManager.darkBlue,
        color: colorManager.white,
        fontSize: "1vw",
        fontWeight: "bold",
        marginTop: "1rem",
        cursor: "pointer",
    },
    buttonContainer: {
        marginHorzintal: "1rem",
        marginRight: "2rem",
        display: "flex",
        justifyContent: "flex-end",
    },
}