import React, { useState, useEffect } from "react";
import MeetingForm from "../Components/ModuleComponents/MeetingForm";
import { useDispatch} from "react-redux";
import { updateSelectedPage} from "../state/navbarSlice";
import PageHeader from "../Components/PageHeader";
import colorManager from "../Styles/ColorManager";
import { modules } from "../data";
import PageTopMargin from "../Components/PageTopMargin";

export default function InvestorsPage() {
  const dispatch = useDispatch();
  const [showMeetingForm, setShowMeetingForm] = useState(false);

  const handleRequestMeeting = () => {
    setShowMeetingForm(true);
  };

  useEffect(() => {
    dispatch(updateSelectedPage("about-us"));
  }, []);

  return (
    <>
      <PageTopMargin/>
      <PageHeader title={"Investors"} />
      <div style={styles.container}>
        <p style={styles.strategyText}>{modules.investors.investmentStartegy}</p>
        <p style={styles.strategyText}>{modules.investors.investorsWeWelcome}</p>
        {!showMeetingForm && (
          <button onClick={handleRequestMeeting} style={styles.button}>Request a meeting</button>
        )}
        {showMeetingForm && <MeetingForm />}
      </div>
    </>
  );
}

const styles = {
  container: {
    padding: "2rem",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
  },
  strategyText: {
    fontSize: "1.2rem",
  },
  button: {
    padding: "1rem 2rem",
    background: colorManager.darkBlue,
    color: colorManager.white,
    width:"50vw",
    border: "none",
    borderRadius: "0.5rem",
    fontSize: "1.2rem",
    fontWeight: "bold",
    cursor: "pointer",
    outline: "none",
  },
};
