import { store, persistor } from './state/store'
import './GlobalStyle.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import { useEffect } from 'react';
import PortofolioPage from './Pages/PortofolioPage';
import HomePage from './Pages/HomePage';
import TeamPage from './Pages/TeamPage';
import ContactUsPage from './Pages/ContactUsPage';
import Navbar from './Components/NavBar';
import Footer from './Components/Footer';
import InvestorsPage from './Pages/InvestorsPage';
import StartupsPage from './Pages/StartupsPage';
function App() {
  useEffect(() => { localStorage.clear() }, [])
  return (
  <div style={{display:"flex", flexDirection:"column", minHeight:"100%", flex:1}}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Navbar/>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/portfolio' element={<PortofolioPage />} />
            <Route path='/team' element={<TeamPage />} />
            <Route path='/contact-us' element={<ContactUsPage />} />
            <Route path='/investors' element={<InvestorsPage/>} />
            <Route path='/startups' element={<StartupsPage/>} />
          </Routes>
        </BrowserRouter>
        <Footer/>
      </PersistGate>
    </Provider>
  </div>
  );
}

export default App;
