import { React, useState, useEffect, useRef } from "react";
import colorManager from "../../Styles/ColorManager";
import emailjs from '@emailjs/browser'

export default function ApplicationForm() {
    const form = useRef();


    const [journeyRider, setJourneyRider] = useState(false);
    const [angelInvestors, setAngelInvestors] = useState(false);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState('');
    const [document, setDocument] = useState(null);
    const [documentError, setDocumentError] = useState(null);
    const [message, setMessage] = useState('');

    const handleJourneyRiderChange = (event) => {
        setJourneyRider(!journeyRider);
        if (event.target.checked)
            setAngelInvestors(false);

    };

    const handleAngelInvestorsChange = (event) => {
        setAngelInvestors(!angelInvestors);
        if (event.target.checked)
            setJourneyRider(false);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleDocumentChange = (event) => {
        const selectedFile = event.target.files[0];
        const FILE_SIZE_IN_MB = 100;
        // File size validation (in bytes)
        const maxSizeInBytes = FILE_SIZE_IN_MB * 1024 * 1024;
        if (selectedFile.size > maxSizeInBytes) {
          setDocument(null);
          setDocumentError(`File size exceeds the limit. Maximum size allowed: ${FILE_SIZE_IN_MB}MB`);
          return;
        }
      
        setDocument(selectedFile);
        setDocumentError(null);
      };
      

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if ((!journeyRider && !angelInvestors) || name === '' || phoneNumber === '' || email === '' || message === '') {
            alert("Invalid Credentials")
            return;
        }
        if (documentError) {
            alert('Please select a valid pitch deck file.');
            return;
        }        
        else {
            alert("Good credentials")
        }
        // emailjs.sendForm('service_kzdisej', 'template_95a7osj', form.current, '46TAJ9n3YQbjELC6O')
        //     .then((result) => {
        //         console.log(result);
        //     }, (error) => {
        //         console.log(error);
        //     });
        // TODO: handle form submission
        setDocument(null); // Reset the document state after submission
        e.target.reset();
    };

    return (
        <div style={styles.wrapper}>
            <h2 style={styles.label}>Application Form</h2>
            <form onSubmit={handleSubmit} ref={form}>
                <div style={styles.container}>
                <label style={styles.journeyText}>Choose Your Journey</label>
                    <div style={styles.firstRow}>
                        <div style={styles.checkboxContainer}>
                            <div>
                                <input type="radio" id="journeyRider" checked={journeyRider} onChange={handleJourneyRiderChange} />
                                <label style={styles.labelText} htmlFor="journeyRider">Journey Rider</label>
                            </div>
                            <div>
                                <input type="radio" id="angelInvestors" checked={angelInvestors} onChange={handleAngelInvestorsChange} />
                                <label style={styles.labelText} htmlFor="angelInvestors">Angel Investors</label>
                            </div>
                        </div>
                        <div style={styles.inputContainer}>
                            <label style={styles.labelText} htmlFor="document">Pitch Deck</label>
                            <input style={styles.inputBox} type="file" id="document" onChange={handleDocumentChange} />
                            {documentError && <p style={styles.errorText}>{documentError}</p>}
                        </div>
                    </div>
                    <div style={styles.secondRow}>
                        <div style={styles.inputContainer}>
                            <label style={styles.labelText} htmlFor="name">Name*</label>
                            <input style={styles.inputBox} type="text" id="name" value={name} onChange={handleNameChange} />
                        </div>
                        <div style={styles.inputContainer}>
                            <label style={styles.labelText} htmlFor="phoneNumber">Phone Number*</label>
                            <input style={styles.inputBox} type="tel" id="phoneNumber" value={phoneNumber} onChange={handlePhoneNumberChange} />
                        </div>
                    </div>
                    <div style={styles.thirdRow}>
                        <div style={styles.inputContainer}>
                            <label style={styles.labelText} htmlFor="user_email">Email*</label>
                            <input style={styles.inputBox} type="email" id="user_email" value={email} onChange={handleEmailChange} />
                        </div>
                        {/* <div style={styles.inputContainer}>
                            <label style={styles.labelText} htmlFor="document">Pitch Deck</label>
                            <input style={styles.inputBox} type="file" id="document" onChange={handleDocumentChange} />
                        </div> */}
                    </div>
                    <label style={styles.labelText} htmlFor="message">Description*</label>
                    <textarea style={styles.descText} id="message" value={message} onChange={handleMessageChange} />
                </div>
                <div style={styles.buttonContainer}>
                    <button type="submit" style={styles.submitButton}>SUBMIT</button>
                </div>
            </form>
        </div>
    );
}

const styles = {
    wrapper: {
        display: "flex",
        backgroundColor: colorManager.cyan,
        height: "60vh",
        padding: "2rem",
        borderRadius: "0.8rem",
        flexDirection: "column",
        minWidth: 0,
        width: "calc(100% - 4rem)", // Add this line to allow the wrapper to shrink when needed
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "1rem",
        marginLeft: "1rem",
    },
    inputContainer: {
        width: "45%",
        display: "flex",
        marginBottom: "1rem",
        flexDirection: "column",
    },
    container: {
        marginLeft: "1em",
    },
    labelText: {
        fontSize: "1em",
        paddingBottom: "0.5rem",
    },
    inputBox: {
        width: "100%",
        borderRadius: "0.25rem",
        border: "none",
        height: "4vh",
    },
    firstRow:{
        display:"flex",
        justifyContent: "space-between",
        alignItems:"center"
    },
    secondRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    thirdRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    descText: {
        height: "7vh",
        width: "100%",
        alignSelf: "center",
        borderRadius: "0.25rem",
        border: "none",
        resize: "none",
    },
    submitButton: {
        width: "10%",
        height: "5vh",
        borderRadius: "0.25rem",
        border: "none",
        backgroundColor: colorManager.darkBlue,
        color: colorManager.white,
        fontSize: "1em",
        fontWeight: "bold",
        marginTop: "1em",
        cursor: "pointer",
    },
    buttonContainer: {
        marginHorzintal: "1rem",
        marginRight: "2rem",
        display: "flex",
        justifyContent: "flex-end",
    },
    errorText:{
        color: colorManager.red,
    },
}