const colorManager = {
  darkBlue: '#114354',
  lightBlue: '#2a94b8',
  cyan:'#cff1fc',
  white: '#FFFFFF',
  red: '#eb3636',
  lightGrey: 'lightgrey',
  black:'black',
};

export default colorManager;