import { React } from 'react';
import colorManager from '../Styles/ColorManager';
import uiInvestmentLogo from '../assets/ui-investments-logo.png';
import { useSelector } from 'react-redux';
import navigate from '../HelperFunctions';
const NavBarButton = ({ text, link, isSelected, onClick }) => {
  return (
    <li ><a style={{...styles.navbarLink, color:isSelected ? colorManager.lightBlue : colorManager.darkBlue}} href={link} onClick={onClick}>{text}</a></li>
  )
}

const handleClick = () => {
  navigate('/');
};


const Navbar = () => {
  const {selectedPage} = useSelector(state => state.navbar);
  return (
    <nav style={styles.navbar}>
      <ul style={styles.navbarList}>
        <li onClick={handleClick}><img style={styles.logo} src={uiInvestmentLogo} alt='ui investments logo'/></li>
        <div style={styles.buttonList}>
          <NavBarButton text={"About Us"} link={"/"} isSelected={selectedPage === 'about-us'}/>
          <NavBarButton text={"Portfolio"} link={"portfolio"} isSelected={selectedPage === 'portfolio'}/>
          <NavBarButton text={"Team"} link={"team"} isSelected={selectedPage === 'team'}/>
          <NavBarButton text={"Contact Us"} link={"contact-us"} isSelected={selectedPage === 'contact-us'} />
        </div>
      </ul>
    </nav>
  );
}


const styles = {
  navbar: {
    backgroundColor: colorManager.white,
    padding: '1rem 2rem',
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    zIndex: '999',
  },
  navbarList: {
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: "0",//to remove padding left
    margin: '0',
  },
  navbarLink: {
    textDecoration: 'none',
    listStyleType: 'none',
    color: colorManager.darkBlue,
    margin: '0 1rem',
    fontSize:"1.2rem",
    fontWeight:"bold",
    cursor:'pointer'
  },
  buttonList: {
    listStyleType: 'none',
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
  },
  logo:{
    height : '2.5rem',
    cursor: 'pointer'
  }
};

export default Navbar;