import React from 'react';
import colorManager from '../Styles/ColorManager';
import { AiFillLinkedin, AiFillFacebook, AiOutlineTwitter, AiFillInstagram } from "react-icons/ai";
import { IconContext } from 'react-icons';
import uiInvestmentLogoWhite from '../assets/ui-investments-logo-white.png';
import { corporate_info } from '../data';

const Footer = () => {
  const handleLinkedInClick = () => {
    window.open(corporate_info.linkedInLink);
  };

  return (
    <footer style={styles.footerContainer}>
      <div style={styles.footer}>
        <img style={styles.logo} src={uiInvestmentLogoWhite} alt='ui investment logo' />
        <div>
          <p style={styles.text}>{corporate_info.email}</p>
          <IconContext.Provider value={{ color: "white" }}>
            <div style={styles.icons}>
              <AiFillLinkedin
                style={{ cursor: "pointer" }}
                onClick={handleLinkedInClick}
              />
              {/* <AiFillFacebook/>
              <AiOutlineTwitter/>
              <AiFillInstagram /> */}
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </footer>
  );
}

const styles = {
  footerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    flex: 1,
    backgroundColor: colorManager.white
  },
  footer: {
    backgroundColor: colorManager.darkBlue,
    padding: '1rem 2rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  icons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: colorManager.white,
    margin: '0rem',
    marginBottom: '1rem'
  },
  logo: {
    height: '2.5rem'
  }
};

export default Footer;
