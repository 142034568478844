
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    selectedPage:'about-us',
    userType: 'Start-Ups'
};

const navbarSlice = createSlice({
    name: "navbar",
    initialState,
    reducers: {
        updateSelectedPage: (state, action) => {
            state.selectedPage = action.payload;
        },
        updateUserType: (state, action) => {
            state.userType = action.payload;
        },
        
    }
});

export default navbarSlice.reducer;
export const { updateSelectedPage, updateUserType} = navbarSlice.actions;