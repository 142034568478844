import React, { useEffect } from "react";
import InfoComponent from "../Components/InfoComponent";
import { team } from "../data";
import TeamIMG from '../assets/Team.png'
import { useDispatch } from "react-redux";
import { updateSelectedPage } from "../state/navbarSlice";
import PageHeader from "../Components/PageHeader";
import PageTopMargin from "../Components/PageTopMargin";

export default function TeamPage() {
    const dispatch = useDispatch();
    useEffect(()=> {dispatch(updateSelectedPage('team'))}, [])
    return (
        <div>
            <PageTopMargin/>
            <PageHeader title={"Team"} />
            {

                Object.keys(team).map(

                    (teamKey, index) => {
                        const { name, title, description, linkedInName, linkedInLink, phoneNumber, email, image } = team[teamKey];
                        const position = index % 2 === 0 ? 'left' : 'right';
                        return <InfoComponent
                            name={name}
                            title={title}
                            description={description}
                            linkedInName={linkedInName}
                            linkedInLink={linkedInLink}
                            phoneNumber={phoneNumber}
                            email={email}
                            position={position}
                            image={image}
                        />;
                    }
                )
            }
        </div>
    )




}
