import { useState } from 'react';
import colorManager from '../Styles/ColorManager';


export const ComponentSwitcher = ({ name1, component1, name2, component2 }) => {
    const [selectedOption, setSelectedOption] = useState(name1);

    return (
        <>
            <div style={styles.optionContainer}>
                <h2
                    style={{
                        ...styles.option,
                        borderBottom: selectedOption === name1 ? '0.2rem solid' : 'none',
                        fontWeight: selectedOption === name1 ? 'bold' : 'normal',
                    }}
                    onClick={() => setSelectedOption(name1)}
                >
                    {name1}
                </h2>
                <h2
                    style={{
                        ...styles.option,
                        borderBottom: selectedOption === name2 ? '0.2rem solid' : 'none',
                        fontWeight: selectedOption === name2 ? 'bold' : 'normal',
                    }}
                    onClick={() => setSelectedOption(name2)}
                >
                    {name2}
                </h2>
            </div>
            {selectedOption === name1 && component1}
            {selectedOption === name2 && component2}
        </>
    )
}

const styles = {
    optionContainer: {
        display: "flex",
        alignSelf:"start"
    },
    option: {
        marginRight: "1rem",
        cursor: "pointer"
    },
};

export default ComponentSwitcher;