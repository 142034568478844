//exits
import faturaLogo from "./assets/Exits/fatura.png"
import potlocLogo from "./assets/Exits/POTLOC.png"
import parachuteCoffeeLogo from "./assets/Exits/parachute_coffee.png"
import gOrendezvousLogo from "./assets/Exits/GOrendezvous.png"
import VolumetricbioLogo from "./assets/Exits/Volumetricbio.png"

//startups
import wellPalLogo from './assets/Startups/WellPal.png'
import bexelLogo from './assets/Startups/bexel.png'
import devNationLogo from'./assets/Startups/DevNation.png'
import elGameyaLogo from './assets/Startups/ElGameya.png'
import ILLALogo from './assets/Startups/ILLA.png'
import nawahLogo from './assets/Startups/NAWAH.png'
import otidaLogo from './assets/Startups/Otida.png'
import OTOLogo from './assets/Startups/OTO.png'
import roboostLogo from './assets/Startups/Roboost.png'
import spireUpLogo from './assets/Startups/Spireup.png'
import tileGreenLogo from './assets/Startups/Tilegreen.png'
import unlockLogo from './assets/Startups/UNLOCK.png'
import vetWorkLogo from './assets/Startups/vetwork.png'
import weeloLogo from './assets/Startups/Weelo.png'
import welnesLogo from './assets/Startups/Welnes.png'

//team
import tarekRoushdyImage from './assets/Team/Tarek-Roushdy.jpg'
import mohamedAbdelkaderImage from './assets/Team/Mohamed-Abdelkader.jpg'
import wessamSamirImage from './assets/Team/Wessam-Samir.jpg'

export const exits = {
    fatura: {
        name: 'Fatura',
        image: faturaLogo,
        link: 'https://www.linkedin.com/company/fatura-%D9%81%D8%A7%D8%AA%D9%88%D8%B1%D8%A9/',
    },
    potloc: {
        name: 'POTLOC',
        image: potlocLogo,
        link: 'https://www.potloc.com/',
    },
    parachuteCoffee: {
        name: 'Parachute Coffee',
        image: parachuteCoffeeLogo,
        link: 'https://parachutecoffee.com/',
    },
    gOrendezvous: {
        name: 'GOrendezvous',
        image: gOrendezvousLogo,
        link: 'https://www.gorendezvous.com/',
    },
    volumetricbio: {
        name: 'Volumetricbio',
        image: VolumetricbioLogo,
        link: 'https://volumetricbio.com/',
    },
};

export const startups = {
    wellPal: {
        name: 'WellPal',
        image: wellPalLogo,
        link: 'https://3attar.com/ar/'
    },
    bexel: {
        name: 'Bexel',
        image:bexelLogo,
        link: 'https://www.bexel.io/'
    },
    devNation:{
        name:'DevNation',
        image: devNationLogo,
        link:'https://www.thedevnation.com/'
    },
    elGameya: {
        name: 'ElGameya',
        image: elGameyaLogo,
        link: 'https://elgameya.net/home/'
    },
    ILLA: {
        name: 'ILLA',
        image: ILLALogo,
        link: 'https://illa.com.eg/'
    },
    nawah: {
        name: 'Nawah',
        image: nawahLogo,
        link: 'https://nawah-scientific.com/'
    },
    otida: {
        name: 'Otida',
        image: otidaLogo,
        link: 'https://otida.com/'
    },
    OTO: {
        name: 'OTO',
        image: OTOLogo,
        link: 'https://otocourses.com/en/home'
    },
    roboost: {
        name: 'Roboost',
        image: roboostLogo,
        link: 'https://roboost.app/'
    },
    spireup: {
        name: 'Spireup',
        image: spireUpLogo,
        link: 'http://spireup.io/'
    },
    tileGreen: {
        name: 'TileGreen',
        image: tileGreenLogo,
        link: 'https://www.linkedin.com/company/tilegreen'
    },
    unlock: {
        name: 'Unlock',
        image: unlockLogo,
        link: 'https://www.unlockassist.com/'
    },
    vetwork: {
        name: 'Vetwork',
        image: vetWorkLogo,
        link: 'https://vetwork.app/'
    },
    weelo: {
        name: 'Weelo',
        image: weeloLogo,
        link: 'https://www.weelo.com.eg/'
    },
    welnes: {
        name: 'Welnes',
        image: welnesLogo,
        link: 'https://welnes.app/'
    },
};


export const corporate_info = {
    email: "info@ui.investments",
    linkedInLink: "https://www.linkedin.com/company/ui-investments/",
    address: "334 Signature Building, 5th Floor, Sharik Hub, South 90th Street, New Cairo",
    location: "https://maps.app.goo.gl/b2bJPCpn8wqsUCQh8",
    iframeLocationSrc : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d215.89951499245922!2d31.481381237816237!3d30.02560296136711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458238a4d05062f%3A0x5eea2a8385485127!2sSharik-Hub%20New-Cairo!5e0!3m2!1sen!2seg!4v1685817481791!5m2!1sen!2seg",
}


export const team = {
    tarekRoushdy: {
        name: "Tarek Roushdy",
        title: "Founder and CEO",
        description: "With 25+ years of experience in the Oil & Gas domain included several exits, UII CEO & Founder has started the next phase, which is mentoring and helping the young entrepreneurs along their journey.",
        linkedInName: "Tarek Roushdy",
        linkedInLink: "https://www.linkedin.com/in/tarekroushdy/",
        phoneNumber: "+201223113928",
        email: "tarek.roushdy@ui.investments",
        image: tarekRoushdyImage
    },
    mohamedAbdelKader: {
        name: "Mohamed Abdelkader",
        title: "",
        description: "With 15+ years of experience in the tech field, enjoying using Mohamed skills to contribute to the exciting technological advances that happen every day, joined UI as the technical arm to support and help startups",
        linkedInName: "Mohamed Abdelkader",
        linkedInLink: "https://www.linkedin.com/in/mohamed-abdelazim-32022230/",
        phoneNumber: "+201065641122",
        email: "mohamed.abdelazim@ui.investments",
        image: mohamedAbdelkaderImage
    },
    wessamSamir: {
        name: "Wessam Samir",
        title: "",
        description: "During 5+ years in the accounting field, always passionate about achieve the mix of the accounting role and the new ideas, wessam joined UI to help the startups for achieving their ideas and dreams.",
        linkedInName: "Wessam Samir",
        linkedInLink: "https://www.linkedin.com/in/wessam-samir-8398b5182/",
        phoneNumber: "+201208404261",
        email: "wessam.samir@ui.investments",
        image: wessamSamirImage
    },
};

export const modules = {
    investors:{
        investmentStartegy: "Our investment strategy stands out for its distinctive approach to providing comprehensive support. We believe in fostering both social and financial success for our startups. With an unwavering dedication to assisting them in achieving their goals, we prioritize uniqueness, differentiation, and confidence at every stage of their journey. ",
        investorsWeWelcome: "We welcome investors who share our vision and seek investment opportunities that align with our goals. Value creation and knowledge transfer are hallmarks of UI Investments' collaborative partnerships. Our aim is to establish enduring relationships with like-minded investors to deliver the utmost value to our carefully selected startups and help them achieve their objectives for a sustainable and prosperous future."
    },
    startups:{
        description:"UI Investments is dedicated to supporting startups that meet specific criteria. When considering investments in new startups, we prioritize the following requirements:",
        investmentCriteria:{
            "Social Impact": "We seek startups that demonstrate a commitment to creating a positive social impact. Whether it's addressing environmental challenges, promoting social equality, or improving the well-being of communities, we value ventures that contribute to the betterment of society.",
            "Early Stage to Pre-seed": "Our focus lies in partnering with startups in their early stages to pre-seed phase. We believe in nurturing promising ventures from their inception, providing them with the necessary resources and guidance to foster their growth and success.",
            "Sustainability": "We are keen on investing in startups that adopt sustainable practices. This includes ventures that prioritize environmental sustainability, resource efficiency, and responsible business practices.",
            "Digital Startup (SaaS)": "UI Investments focuses on digital startups, especially those operating within the Software-as-a-Service (SaaS) domain. We recognize the potential for innovation and scalability in this sector and actively seek opportunities to support such ventures.",
            "Added Value": "We are interested in startups that offer unique and differentiated value propositions. Whether it's through disruptive technology, a novel business model, or a fresh approach to a market, we aim to partner with startups that bring added value to their industry."
          
        },
        outro:"By adhering to these criteria, UI Investments ensures that our portfolio comprises startups that align with our vision and have the potential to make a lasting impact in their respective fields.",
        journeyModule:{
            description:
                "The unique and comprehensive investment 'Journey Rider' module is specifically designed to cater to idea-stage and early-stage startups, irrespective of whether they are digital or non-digital ventures. Our aim is to assist these startups in overcoming obstacles and facilitating their business growth by providing essential funding and a range of additional support services that set us apart from traditional investment firms.",
            support:
                "In this module, UI Investments ensures that startups receive the necessary financial resources in addition to a host of added value support. This support encompasses various aspects critical to startup success, including mentorship, strategic guidance, access to industry networks, and expert insights assisting startups in navigating the complexities of entrepreneurship and achieving sustainable growth.",
            ecosystem:
                "By partnering with us, startups gain access to a holistic ecosystem that fosters their development, enabling them to focus on their core business activities while receiving invaluable support along their entrepreneurial journey."
        },
        angelInvestorModule:{
            description:
            "UI Investments takes pride in its role as an esteemed angel investor, dedicated to supporting promising startups on their path to success. Our approach is rooted in a deep belief in the entrepreneurial spirit and a passion for innovation. We actively seek out and identify exceptional startups with high growth potential, aiming to create mutually beneficial partnerships that drive value creation.",
            activeInvestments:
            "Our active investments involve a deeper level of engagement. Alongside providing funding, we actively participate in the operational processes and management decisions of the startup. We offer valuable advice, guidance, and mentoring when needed."
        }
    }
};