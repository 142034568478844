import { React, useState, useEffect } from "react";
import ComponentSwitcher from "../Components/ComponentSwitcher";
import colorManager from "../Styles/ColorManager";
import { exits, startups } from "../data";
import { useDispatch } from "react-redux";
import { updateSelectedPage } from "../state/navbarSlice";
import PageHeader from "../Components/PageHeader";
import PageTopMargin from "../Components/PageTopMargin";




const Card = ({ image, text, link }) => {
  const [hovered, setHovered] = useState(false); // State to track hover status

  const styles = {
    containerStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    logoStyle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      height:'5rem',
      width:'5rem',
      backgroundImage: `url(${image})`,
      backgroundSize: hovered ? '110%' : 'cover', // Zoom in background on hover
      backgroundPosition: 'center',
      borderRadius: '0.2rem',
    },
    textStyle: {
      padding: '0.5rem',
      color: hovered ? colorManager.lightBlue : colorManager.darkBlue, // Use state to determine text color
      fontWeight: 'bold',
      borderRadius: '0 0 0.2rem 0.2rem',
      textAlign: 'center',
      transition: 'color 0.3s',
    },
  };

  const handleHover = () => {
    setHovered(true); // Set hovered state to true on mouse enter
  };

  const handleHoverOut = () => {
    setHovered(false); // Set hovered state to false on mouse leave
  };

  const handleClick = () => {
    window.open(link);
  }

  return (
    <div
      style={styles.containerStyle}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverOut}
      onClick={handleClick}
    >
      <div style={styles.logoStyle}></div>
      <div style={styles.textStyle}>{text}</div>
    </div>
  );
};

const ResponsiveGrid = ({ list }) => {
  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(7rem, 1fr))',
    gridGap: '20px',
    padding: '2rem 0rem',
    justifyContent: 'center',  // Center horizontally
    alignItems: 'start',  // Center vertically
  };

  const gridItemStyle = {
    backgroundColor: '#ccc',
    padding: '1rem',
    textAlign: 'center',
  };

  return (
    <div style={gridContainerStyle}>
      {Object.keys(list).map((listKey) => {
        const { name, image, link } = list[listKey];
        return <Card style={gridItemStyle} key={listKey} text={name} image={image} link={link} />;
      })}
    </div>
  );
};


export default function PortofolioPage() {
  const dispatch = useDispatch();
  useEffect(()=> {dispatch(updateSelectedPage('portfolio'))}, [])

  return (
    <div>
      <PageTopMargin/>
      <PageHeader title={"Portfolio"}/>
      <div style={styles.container}>
        <ComponentSwitcher name1={"Exits"} name2={"Startups"} component1={<ResponsiveGrid list={exits} />} component2={<ResponsiveGrid list={startups} />} />
      </div>
    </div>
  )
}

const styles = {
  container: {
      padding: "2rem",
    },

};